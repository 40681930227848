@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");

html {
    @apply bg-[#fbfbf1]
  }

  @layer base {
    html {
      font-family: "Courier New", Courier, monospace;
    }

    html, body {
      overflow-x: hidden;
    }
  }